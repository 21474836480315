import React, {Fragment} from "react"
import "bootstrap/dist/css/bootstrap.css"
import "./MarketplaceFilter.css"
function MarketplaceFilter(props) {
 
  return (
   <Fragment>
<form className="row g-3">
<legend>Search for the perfect contractor</legend>
<div id="contractorSearchsubtitle" className="form-text">
               apply some filters and click search
              </div>
  <div className="col-md-4">
    <label htmlFor="inputEmail4" className="form-label">Company Name</label>
    <input type="search" className="form-control" id="inputEmail4"/>
    <div className="col-md-12">
    Discipline(s)

    <select className="form-select" multiple aria-label="Multiple select example">
  <option selected>Open this select menu</option>
  <option value="1">One</option>
  <option value="2">Two</option>
  <option value="3">Three</option>
</select>
      </div>
    
  </div>
  <div className="col-md-8">

  <label htmlFor="inputEmail4" className="form-label">Company Name</label>
    <input type="search" className="form-control" id="inputEmail4"/>

  <label htmlFor="inputEmail4" className="form-label">Company Name</label>
    <input type="search" className="form-control" id="inputEmail4"/>

  <label htmlFor="inputEmail4" className="form-label">Company Name</label>
    <input type="search" className="form-control" id="inputEmail4"/>

   <label htmlFor="inputEmail4" className="form-label">Company Name</label>
   <input type="search" className="form-control" id="inputEmail4"/>
 </div>

  <div className="col-12">
    <label htmlFor="inputAddress" className="form-label">Address</label>
    <input type="text" className="form-control" id="inputAddress" placeholder="1234 Main St"/>
  </div>
  <div className="col-12">
    <label htmlFor="inputAddress2" className="form-label">Address 2</label>
    <input type="text" className="form-control" id="inputAddress2" placeholder="Apartment, studio, or floor"/>
  </div>
  <div className="col-md-6">
    <label htmlFor="inputCity" className="form-label">City</label>
    <input type="text" className="form-control" id="inputCity"/>
  </div>
  <div className="col-md-4">
    <label htmlFor="inputState" className="form-label">State</label>
    <select id="inputState" className="form-select">
      <option selected>Choose...</option>
      <option>...</option>
    </select>
  </div>
  <div className="col-md-2">
    <label htmlFor="inputZip" className="form-label">Zip</label>
    <input type="text" className="form-control" id="inputZip"/>
  </div>
  <div className="col-12">
    <div className="form-check">
      <input className="form-check-input" type="checkbox" id="gridCheck"/>
      <label className="form-check-label" htmlFor="gridCheck">
        Check me out
      </label>
    </div>
  </div>
  <div className="col-12">
    <button type="submit" className="btn btn-primary">Sign in</button>
  </div>
</form>
   </Fragment>
  )
}
export default MarketplaceFilter
