import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import "./Contractors.css"
import "bootstrap/dist/css/bootstrap.css"
import "react-tooltip/dist/react-tooltip.css"
import axios from "./config/axios-configure"
import "react-quill/dist/quill.snow.css" // Import Quill styles

function Contractors(props) {
  const reportServiceUri = process.env.REACT_APP_REPORT_SERVICE_URL

  const { guid } = useParams() // Contractor Guid

  const [contractorCore, setContractorCore] = useState([])

  useEffect(() => {
    // Load the contractor list
    const config = {
      headers: {
        //Authorization: "66D5269F-9E79-4D3D-A59D-CC4356428BE6",
        //  Authorization: process.env.SEC_TOKEN,
         Authorization: sessionStorage.getItem("loginGuid"),
      },
    }
    axios
      .get(
        reportServiceUri +
          "/client/contractor/core?contractorguid=" +
          guid,
        config
      )
      .then((response) => {
        setContractorCore(response.data)
      })
      .catch((error) => {
        console.error(error)
      })
  }, [reportServiceUri, guid])

  return (
    <React.Fragment>
      <div className="row pt-4">
        <div className="col-3">Name</div>
        <div className="col-7">
          <div className="form-floating">{contractorCore?.name}</div>
        </div>
        <div className="col-2 text-end"></div>
      </div>
      <div className="row pt-4">
        <div className="col-3">Address</div>
        <div className="col-9">
          <div className="form-floating">{contractorCore?.address}</div>
        </div>
      </div>
      <div className="row pt-4">
        <div className="col-3">Disciplines</div>
        <div className="col-9">
          <div className="form-floating">{contractorCore?.discipline}</div>
        </div>
      </div>
      <div className="row pt-4">
        <div className="col">
          <div className="card">
            <div className="card-header">Contact Information</div>
            <div className="card-body">
              <div className="row pt-4">
                <div className="col-3">Email</div>
                <div className="col-9"><a href = {"mailto:" + contractorCore.email}>{contractorCore.email}</a></div>
              </div>
              <div className="row pt-4">
                <div className="col-3">Phone</div>
                <div className="col-9"><a href={"tel:" + contractorCore.phone}>{contractorCore.phone}</a></div>
              </div>
              <div className="row pt-4">
                <div className="col-3">Contact</div>
                <div className="col-9">{contractorCore.contact}</div>
              </div>
              <div className="row pt-4">
                <div className="col-3">Mobile</div>
                <div className="col-9"><a href={"tel:" + contractorCore.mobile}>{contractorCore.mobile}</a></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
export default Contractors
