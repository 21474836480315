import React, { useState, useEffect, Fragment } from "react"
import { NavLink } from "react-router-dom"
import Footer from "./components/Footer"
import AuthenticatedHeader from "./components/AuthenticatedHeader"
import "./Contractors.css"
import "bootstrap/dist/css/bootstrap.css"
import "react-tooltip/dist/react-tooltip.css"
import { Tooltip } from "react-tooltip"
import axios from "./config/axios-configure"
import "react-quill/dist/quill.snow.css" // Import Quill styles


function Contractors(props) {
  const reportServiceUri = process.env.REACT_APP_REPORT_SERVICE_URL

  const [contractors, setContractors] = useState([])

  useEffect(() => {
    // Load the contractor list
    const config = {
      headers: {
     //   Authorization: '66D5269F-9E79-4D3D-A59D-CC4356428BE6',
     //  Authorization: process.env.SEC_TOKEN,
        Authorization: sessionStorage.getItem("loginGuid"),
      },
    }
    axios
      .get(reportServiceUri + "/client/contractor/list", config)
      .then((response) => {
        setContractors(response.data)
      })
      .catch((error) => {
        console.error(error)
      })
  }, [reportServiceUri])

  return (
    <div className="wrapper">
      <AuthenticatedHeader
       
      ></AuthenticatedHeader>
      <div className="container px-4 py-5 ">
        <div className="row align-items-start border-bottom pb-1">
        <nav aria-label="breadcrumb">
  <ol className="breadcrumb">
    <li className="breadcrumb-item"><a className="navbar-item" href={"https://login-demo.comply-x.com/auth/" + sessionStorage.getItem("loginGuid")}>Dashboard{" "}</a></li>
  </ol>
</nav>
          <div className="row">
            <div className="col">
              <h2>
                Contractors
              </h2>
            </div>
            <div className="col text-end noRightPadding">
            <NavLink className="noBgColour" to="/marketplace" exact="true">
              Marketplace
            </NavLink>      </div>
          </div>
        </div>
        <div className="form-floating">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Address</th>
                <th scope="col">Disciplines</th>
                <th scope="col">Status</th>
                <th scope="col" className="text-end">
                  Documentation
                </th>
              </tr>
            </thead>
            <tbody>
              {contractors.map((contractor) => (
                <tr key={contractor.guid}>
                  <td>
                    <NavLink
                      to={"/contractordisplay/" + contractor.guid}
                      exact="true"
                    >
                      {contractor.name}
                    </NavLink>
                  </td>
                  <td>{contractor.address}</td>
                  <td>{contractor.discipline}</td>
                  <td>
                  <span className="badge bg-secondary">Active</span>
                  </td>
                  <td className="text-end">
                    {contractor.contractorClientFields?.map((fieldValue) => (
                      <Fragment key={fieldValue.fieldValueId}>
                        {fieldValue.expired ? (
                          <Fragment>
                            <Tooltip
                              anchorSelect={
                                ".my-anchor-element" + fieldValue.fieldValueId
                              }
                              place="top"
                            >
                              <div>
                                <p className="fs-5">
                                  {fieldValue.assetTypeFieldName}
                                </p>
                                <p>{fieldValue.assetTypeFieldValue}</p>
                                <hr />
                                {fieldValue.reviewDate !== null && (
                                  <Fragment>
                                    <span className="badge rounded-pill text-bg-dark noLeftPadding">
                                      Review Date:
                                    </span>
                                    <span className="badge bg-danger">
                                      {fieldValue.reviewDate}
                                    </span>
                                    <hr />
                                  </Fragment>
                                )}
                                {fieldValue.statusName !== null ? (
                                  <p className="fw-lighter">
                                    <span className="badge rounded-pill text-bg-dark noLeftPadding">
                                      Status:
                                    </span>
                                    <span className="badge text-bg-light">
                                      {fieldValue.statusName}
                                    </span>
                                  </p>
                                ) : (
                                  <p className="fw-lighter">No Status Set</p>
                                )}
                                <p className="fw-lighter">
                                  Date Added: {fieldValue.dateOnSystem}
                                </p>
                              </div>
                            </Tooltip>
                            <span
                              className={
                                "my-anchor-element" + fieldValue.fieldValueId
                              }
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="23"
                                height="23"
                                fill={
                                  fieldValue.colour === "#fff"
                                    ? "#000000"
                                    : fieldValue.colour
                                }
                                className="bi bi-bookmark-x-fill"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M2 15.5V2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.74.439L8 13.069l-5.26 2.87A.5.5 0 0 1 2 15.5zM6.854 5.146a.5.5 0 1 0-.708.708L7.293 7 6.146 8.146a.5.5 0 1 0 .708.708L8 7.707l1.146 1.147a.5.5 0 1 0 .708-.708L8.707 7l1.147-1.146a.5.5 0 0 0-.708-.708L8 6.293 6.854 5.146z"
                                />
                              </svg>
                            </span>
                          </Fragment>
                        ) : (
                          <>
                            {fieldValue.nearlyExpired ? (
                              <Fragment>
                                <Tooltip
                                  anchorSelect={
                                    ".my-anchor-element" +
                                    fieldValue.fieldValueId
                                  }
                                  place="top"
                                >
                                  <div>
                                    <p className="fs-5">
                                      {fieldValue.assetTypeFieldName}
                                    </p>
                                    <p>{fieldValue.assetTypeFieldValue}</p>
                                    <hr />
                                    {fieldValue.reviewDate !== null && (
                                      <Fragment>
                                        <span className="badge text-bg-dark noLeftPadding">
                                          Review Date:
                                        </span>
                                        <span className="badge text-bg-warning">
                                          {fieldValue.reviewDate}
                                        </span>
                                        <hr />
                                      </Fragment>
                                    )}
                                    {fieldValue.statusName !== null ? (
                                      <p className="fw-lighter">
                                        <span className="badge rounded-pill text-bg-dark noLeftPadding">
                                          Status:
                                        </span>
                                        <span className="badge text-bg-light">
                                          {fieldValue.statusName}
                                        </span>
                                      </p>
                                    ) : (
                                      <p className="fw-lighter">
                                        No Status Set
                                      </p>
                                    )}
                                    <p className="fw-lighter">
                                      Date Added: {fieldValue.dateOnSystem}
                                    </p>
                                  </div>
                                </Tooltip>
                                <span
                                  className={
                                    "my-anchor-element" +
                                    fieldValue.fieldValueId
                                  }
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="23"
                                    height="23"
                                    fill={
                                      fieldValue.colour === "#fff"
                                        ? "#000000"
                                        : fieldValue.colour
                                    }
                                    className="bi bi-bookmark-dash"
                                    viewBox="0 0 16 16"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M5.5 6.5A.5.5 0 0 1 6 6h4a.5.5 0 0 1 0 1H6a.5.5 0 0 1-.5-.5z"
                                    />
                                    <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.777.416L8 13.101l-5.223 2.815A.5.5 0 0 1 2 15.5V2zm2-1a1 1 0 0 0-1 1v12.566l4.723-2.482a.5.5 0 0 1 .554 0L13 14.566V2a1 1 0 0 0-1-1H4z" />
                                  </svg>
                                </span>
                              </Fragment>
                            ) : (
                              <Fragment>
                                <Tooltip
                                  anchorSelect={
                                    ".my-anchor-element" +
                                    fieldValue.fieldValueId
                                  }
                                  place="top"
                                >
                                  <div>
                                    <p className="fs-5">
                                      {fieldValue.assetTypeFieldName}
                                    </p>
                                    <p>{fieldValue.assetTypeFieldValue}</p>
                                    <hr />
                                    {fieldValue.reviewDate !== null ? (
                                      <Fragment>
                                        <span className="badge rounded-pill text-bg-dark">
                                          Review Date:
                                        </span>
                                        <span className="badge bg-secondary">
                                          {fieldValue.reviewDate}
                                        </span>
                                      </Fragment>
                                    ) : (
                                      <p>No review date set</p>
                                    )}
                                    <hr />
                                    {fieldValue.statusName !== null ? (
                                      <p className="fw-lighter">
                                        <span className="badge rounded-pill text-bg-dark noLeftPadding">
                                          Status:
                                        </span>
                                        <span className="badge text-bg-light">
                                          {fieldValue.statusName}
                                        </span>
                                      </p>
                                    ) : (
                                      <p className="fw-lighter">
                                        No Status Set
                                      </p>
                                    )}
                                    <p className="fw-lighter">
                                      Date Added: {fieldValue.dateOnSystem}
                                    </p>
                                  </div>
                                </Tooltip>
                                <span
                                  className={
                                    "my-anchor-element" +
                                    fieldValue.fieldValueId
                                  }
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="23"
                                    height="23"
                                    fill={
                                      fieldValue.colour === "#fff"
                                        ? "#000000"
                                        : fieldValue.colour
                                    }
                                    className="bi bi-bookmark"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.777.416L8 13.101l-5.223 2.815A.5.5 0 0 1 2 15.5V2zm2-1a1 1 0 0 0-1 1v12.566l4.723-2.482a.5.5 0 0 1 .554 0L13 14.566V2a1 1 0 0 0-1-1H4z" />
                                  </svg>
                                </span>
                              </Fragment>
                            )}
                          </>
                        )}
                      </Fragment>
                    ))}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <Footer></Footer>
    </div>
  )
}
export default Contractors
