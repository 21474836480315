import React, { useState } from "react"
import Footer from "./components/Footer"
import AuthenticatedHeader from "./components/AuthenticatedHeader"
import "./Contractors.css"
import "bootstrap/dist/css/bootstrap.css"
import "react-tooltip/dist/react-tooltip.css"
import "react-quill/dist/quill.snow.css" // Import Quill styles
import ContractorCard from './components/marketplace/ContractorCard'
import MarketplaceFilter from "./MarketplaceFilter"

function Marketplace(props) {
 
  const[showFilter, setShowFilter] = useState(false)
  return (
    <div className="wrapper">
    <AuthenticatedHeader
     
    ></AuthenticatedHeader>
    <div className="container px-4 py-5 ">
      <div className="row align-items-start border-bottom pb-1">
      <nav aria-label="breadcrumb">
<ol className="breadcrumb">
  <li className="breadcrumb-item"><a className="navbar-item" href={"https://login-demo.comply-x.com/auth/" + sessionStorage.getItem("loginGuid")}>Dashboard{" "}</a></li>
</ol>
</nav>
        <div className="row">
          <div className="col">
            <h2>
              Marketplace
            </h2>
          </div>
          <div className="col text-end noRightPadding">
          <button type="button" onClick={() => setShowFilter(true)} className="btn nopadding">
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30"  fill="#00e39c" className="bi bi-search" viewBox="0 0 16 16">
  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
</svg>
</button>
          </div>
        </div>
      </div>
      <div className="form-floating row">
{showFilter &&(
  <MarketplaceFilter></MarketplaceFilter>
)}

       <ContractorCard></ContractorCard>
       <ContractorCard></ContractorCard>
       <ContractorCard></ContractorCard>
        <ContractorCard></ContractorCard>
       <ContractorCard></ContractorCard>
       <ContractorCard></ContractorCard>
       <ContractorCard></ContractorCard>
       <ContractorCard></ContractorCard>
      </div>
    </div>

    <Footer></Footer>
  </div>
  )
}
export default Marketplace
