import React from "react"
import "bootstrap/dist/css/bootstrap.css"

function ContractorCard(props) {
  return (
    <React.Fragment>
      <div className="col-sm-3 g-4">
        <div className="card">
          <img
            src="https://static.vecteezy.com/system/resources/previews/010/930/001/original/google-logo-icon-illustration-free-vector.jpg"
            className="card-img-top"
            alt="..."
          />
          <div className="card-body">
            <h5 className="card-title">Google PLC</h5>
            <p className="card-text">This is a great company</p>
            <button type="button" className="btn btn-link">Details</button>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
export default ContractorCard
