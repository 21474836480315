import React from 'react';
import ReactDOM from 'react-dom/client';
import App from "./App";
import ErrorPage from "./error_page";
import reportWebVitals from './reportWebVitals';
import Auth from "./Auth"
import Contractors from './Contractors';
import ContractorDisplay from './ContractorDisplay'
import Marketplace from './Marketplace';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/auth/:token",
    element: <Auth/>,
  },
  {
    path: "/contractordisplay/:guid",
    element: <ContractorDisplay/>,
  },
  {
    path: "/marketplace/",
    element: <Marketplace/>,
  },
  {
    path: "/contractors",
    element: <Contractors/>,
  },
]);
ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();