import React from "react"
import { NavLink } from "react-router-dom"
import Footer from "./components/Footer"
import AuthenticatedHeader from "./components/AuthenticatedHeader"
import "./Contractors.css"
import "bootstrap/dist/css/bootstrap.css"
import { Tooltip } from "react-tooltip"
import "react-tooltip/dist/react-tooltip.css"
import "react-quill/dist/quill.snow.css" // Import Quill styles
import ContractorCore from "./ContractorCore"
import Notes from "./components/Notes"
function ContractorDisplay(props) {

  return (
    <div className="wrapper">
      <AuthenticatedHeader></AuthenticatedHeader>
      <div className="container px-4 py-5 ">
        <div className="row align-items-start border-bottom pb-1">
        <nav aria-label="breadcrumb">
  <ol className="breadcrumb">
    <li className="breadcrumb-item"><a className="navbar-item" href={"https://login-demo.comply-x.com/auth/" + sessionStorage.getItem("loginGuid")}>Dashboard{" "}</a></li>
    <li className="breadcrumb-item"> <NavLink className="noBgColour" to="/contractors" exact="true">
              Contrators
            </NavLink></li>
  </ol>
</nav>


        
           

          <div className="row">
            <div className="col">
              <h2>Contractor Information</h2>
            </div>
            <div className="col text-end noRightPadding"></div>
          </div>
        </div>
        <div className="row">
          <div className="col-7">
            <ContractorCore guid={props.guid}></ContractorCore>
          </div>
          <div className="col-5 bg-body-secondary text-emphasis-light">
            <div className="text-end pt-2 pb-2">
              <Tooltip anchorSelect={".my-anchor-element-secure"} place="top">
                <div>
                  Client use only. Contractors have no access to this data
                </div>
              </Tooltip>
              <span className={"my-anchor-element-secure"}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  fill="currentColor"
                  className="bi bi-file-lock2"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 5a1 1 0 0 1 1 1v1H7V6a1 1 0 0 1 1-1zm2 2.076V6a2 2 0 1 0-4 0v1.076c-.54.166-1 .597-1 1.224v2.4c0 .816.781 1.3 1.5 1.3h3c.719 0 1.5-.484 1.5-1.3V8.3c0-.627-.46-1.058-1-1.224z" />
                  <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z" />
                </svg>
              </span>
            </div>
            <Notes guid={props.guid}></Notes>
            <div className="mt-3"></div>
           
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  )
}
export default ContractorDisplay
