import React, { useState, useEffect, Fragment } from "react"
import "bootstrap/dist/css/bootstrap.css"
import { useParams } from "react-router-dom"
import "bootstrap/dist/js/bootstrap.bundle.js"
import { Tooltip } from "react-tooltip"
import axios from "../config/axios-configure"
import Modal from "react-bootstrap/Modal"
import "./Notes.css"
import { CirclePicker } from "react-color"

const reportServiceUri = process.env.REACT_APP_REPORT_SERVICE_URL

function Notes(props) {
  const [showAddNewNote, setShowAddNewNote] = useState(false)
  const { guid } = useParams() // Contractor Guid
  const [isFeatured, setIsFeatured] = useState(false)

  const [isPrivate, setIsPrivate] = useState(false)
  const [deleteErrorMessage, setDeleteErrorMessage] = useState(null)
  const [isSaving, setIsSaving] = useState(false)

  const [globalEdit, setGlobalEdit] = useState(false) // global edit settings

  /*
  Delete Message modal and ID settings
  */
  const [show, setShow] = useState(false) // Shows the Delete Message modal
  const [selectedNoteIdForDelete, setSelectedNoteIdForDelete] = useState("")

  const [newNoteText, setNewNoteText] = useState("")
  const [errorMessage, setErrorMessage] = useState("")

  const [featureColour, setFeatureColour] = useState("#0079ff")

  const [notes, setNotes] = useState([])

  const handleClose = () => setShow(false)

  const handleShow = (noteid) => {
    setSelectedNoteIdForDelete(noteid)
    setShow(true)
    setDeleteErrorMessage(null) // reset any error message
  }

  const handlesetEditFeatureColour = (color) => {
    setEditFeatureColour(color.hex)
  }

  /*
All the vars needed for eit note functionallity
  */
  const [editNoteText, setEditNoteText] = useState("")
  const [editFeatureColour, setEditFeatureColour] = useState("")
  const [editIsFeatured, setEditIsFeatured] = useState(false)
  const [editIsPrivate, setEditIsPrivate] = useState(false)
  const [editNoteId, setEditNoteId] = useState("")
  // This will toggle the edit note boolean - AND initially set it if it doesnt exist
  const toggleEditNote = (noteId) => {
    const elementsIndex = notes?.findIndex((note) => note.noteId === noteId)

    /*
    Set the Edit variables to the data within the editable note
    */
    setEditNoteId(noteId)
    setEditNoteText(notes[elementsIndex].notetext)
    setEditFeatureColour(notes[elementsIndex].featurecolour)
    setEditIsFeatured(notes[elementsIndex].isfeatured)
    setEditIsPrivate(notes[elementsIndex].private)
    setErrorMessage(null) // reset the error message
    let currenteditvalue = notes[elementsIndex].edit
    if (currenteditvalue !== true && currenteditvalue !== false) {
      currenteditvalue = false
    }

    let newArray = [...notes]
    newArray[elementsIndex] = {
      ...newArray[elementsIndex],
      edit: !currenteditvalue,
    }
    setNotes(newArray)
  }

  const handleSetFeatureColour = (color) => {
    setFeatureColour(color.hex)
  }

  /*
Display the add note UI and reset the vars
  */
  const addNewNoteAndClearVars = () => {
    setNewNoteText("")
    setIsFeatured(false)
    setShowAddNewNote(true)
    setIsPrivate(false)
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    setIsSaving(true)

    const config = {
      headers: {
       // Authorization: "66D5269F-9E79-4D3D-A59D-CC4356428BE6",
          Authorization: sessionStorage.getItem("loginGuid"),
      },
    }
    axios
      .post(
        reportServiceUri + "/note/add/clientcontractor",
        {
          notetext: newNoteText,
          isfeatured: isFeatured,
          featurecolour: featureColour,
          contractorGuid: guid,
          private: isPrivate,
        },
        config
      )
      .then((response) => {
        if (response.status === 200) {
          setShowAddNewNote(false)
          setIsFeatured(false)
          setNewNoteText("")
          getNotes()
          setIsSaving(false)
        } else {
          setIsSaving(false)
          setErrorMessage(response.body)
        }
      })
      .catch((error) => {
        setIsSaving(false)
        setErrorMessage(error.response.data)
      })
  }

  const changeFeaturedTick = (e) => {
    setIsFeatured(e.target.checked)
  }

  const changeEditFeaturedTick = (e) => {
    setEditIsFeatured(e.target.checked)
  }

  const changeIsPrivateTick = (e) => {
    setIsPrivate(e.target.checked)
  }

  const changeEditIsPrivateTick = (e) => {
    setEditIsPrivate(e.target.checked)
  }

  const removeNote = () => {
    setDeleteErrorMessage(null)
    const config = {
      headers: {
       // Authorization: "66D5269F-9E79-4D3D-A59D-CC4356428BE6",
           Authorization: sessionStorage.getItem("loginGuid"),
      },
    }
    axios
      .get(
        reportServiceUri + "/note/delete?noteid=" + selectedNoteIdForDelete,
        config
      )
      .then((response) => {
        handleClose()
        getNotes()
        setGlobalEdit(false)
      })
      .catch((error) => {
        setDeleteErrorMessage("Unable to delete, an error has occured")
        console.error(error)
      })
  }

  const getNotes = () => {
    // Load the contractor list
    const config = {
      headers: {
      //  Authorization: "66D5269F-9E79-4D3D-A59D-CC4356428BE6",
        //  Authorization: process.env.SEC_TOKEN,
         Authorization: sessionStorage.getItem("loginGuid"),
      },
    }
    axios
      .get(
        reportServiceUri +
          "/note/clientcontractor/getlist?contractorguid=" +
          guid,
        config
      )
      .then((response) => {
        setNotes(response.data)
      })
      .catch((error) => {
        console.error(error)
      })
  }

  useEffect(() => {
    // Load the contractor list
    const config = {
        headers: {
        //  Authorization: "66D5269F-9E79-4D3D-A59D-CC4356428BE6",
          //  Authorization: process.env.SEC_TOKEN,
           Authorization: sessionStorage.getItem("loginGuid"),
        },
      }
      axios
        .get(
          reportServiceUri +
            "/note/clientcontractor/getlist?contractorguid=" +
            guid,
          config
        )
        .then((response) => {
          setNotes(response.data)
        })
        .catch((error) => {
          console.error(error)
        })
  }, [guid])

  const editNote = (e) => {
    e.preventDefault()

    setIsSaving(true)

    const config = {
      headers: {
       // Authorization: "66D5269F-9E79-4D3D-A59D-CC4356428BE6",
          Authorization: sessionStorage.getItem("loginGuid"),
      },
    }
    axios
      .post(
        reportServiceUri + "/note/edit",
        {
          notetext: editNoteText,
          noteId: editNoteId,
          isfeatured: editIsFeatured,
          featurecolour: editFeatureColour,
          private: editIsPrivate,
        },
        config
      )
      .then((response) => {
        if (response.status === 200) {
          setShowAddNewNote(false)
          setIsFeatured(false)
          setNewNoteText("")
          getNotes()
          setIsSaving(false)
          toggleEditNote(editNoteId)
        } else {
          setErrorMessage("Update Error")
          setIsSaving(false)
        }
      })
      .catch((error) => {
        setIsSaving(false)
        setErrorMessage(error.response.data)
      })
  }

  return (
    <React.Fragment>
      <div className="card">
        <div className="card-header">
          <div className="row">
            <div className="col-11">
              <span className="pe-1">Notes</span>
              {!showAddNewNote && (
                <button
                  className="btn addbtn nopadding"
                  onClick={() => addNewNoteAndClearVars()}
                  type="button"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    fill="#00e39c"
                    className="bi bi-plus-circle-fill"
                    viewBox="0 0 20 20"
                  >
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                  </svg>
                </button>
              )}
            </div>

            <div className="col-1 text-end">
              {notes?.length > 0 && !globalEdit && (
                <button
                  className="btn addbtn nopadding"
                  onClick={() => setGlobalEdit(true)}
                  type="button"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-pencil-square"
                    viewBox="0 0 16 16"
                  >
                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                    <path
                      fillRule="evenodd"
                      d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                    />
                  </svg>
                </button>
              )}
              {notes?.length > 0 && globalEdit && (
                <button
                  className="btn addbtn nopadding"
                  onClick={() => setGlobalEdit(false)}
                  type="button"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-x-square"
                    viewBox="0 0 16 16"
                  >
                    <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                  </svg>
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="card-body">
          {showAddNewNote ? (
            <form onSubmit={handleSubmit}>
              <label htmlFor="assetDescription" className="form-label">
                Add new note
              </label>
              <div className="float-end">
                <button
                  type="button"
                  onClick={() => setShowAddNewNote(false)}
                  className="btn nopadding"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22"
                    fill="#CB444A"
                    className="bi bi-x-square"
                    viewBox="0 0 16 16"
                  >
                    <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                  </svg>
                </button>
                <span className="pe-3"></span>
                {isSaving ? (
                  <div
                    className="spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span className="visually-hidden">Saving...</span>
                  </div>
                ) : (
                  <button type="submit" className="btn nopadding">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="22"
                      fill="#00e39c"
                      className="bi bi-floppy2"
                      viewBox="0 0 16 16"
                    >
                      <path d="M1.5 0h11.586a1.5 1.5 0 0 1 1.06.44l1.415 1.414A1.5 1.5 0 0 1 16 2.914V14.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 14.5v-13A1.5 1.5 0 0 1 1.5 0ZM1 1.5v13a.5.5 0 0 0 .5.5H2v-4.5A1.5 1.5 0 0 1 3.5 9h9a1.5 1.5 0 0 1 1.5 1.5V15h.5a.5.5 0 0 0 .5-.5V2.914a.5.5 0 0 0-.146-.353l-1.415-1.415A.5.5 0 0 0 13.086 1H13v3.5A1.5 1.5 0 0 1 11.5 6h-7A1.5 1.5 0 0 1 3 4.5V1H1.5a.5.5 0 0 0-.5.5Zm9.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-1Z" />
                    </svg>
                  </button>
                )}
              </div>
              <textarea
                className="form-control"
                value={newNoteText}
                onChange={(event) => {
                  setNewNoteText(event.target.value)
                }}
                id="nwNoteText"
                required={true}
                rows="3"
              ></textarea>
              <div id="assetTypeNameHelp" className="form-text">
                {isPrivate ? (
                  <span>
                    This is a private note which can only be seen by you. Users
                    in your organisaiton are unable to see this note.
                  </span>
                ) : (
                  <span>
                    This note can be seen by users in your organisaiton.
                  </span>
                )}
              </div>

              <div className="form-check form-switch pt-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value={isPrivate}
                  onChange={changeIsPrivateTick}
                  role="switch"
                  id="isPrivateSwitch"
                />
                <label className="form-check-label" htmlFor="isPrivateSwitch">
                  Private
                </label>
              </div>

              <div className="form-check form-switch pt-3">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  value={isFeatured}
                  onChange={changeFeaturedTick}
                  id="flexCheckDefault"
                />
                <label className="form-check-label" htmlFor="flexCheckDefault">
                  Featured
                </label>
              </div>

              <div id="featureTickHelp" className="form-text">
                Featured notes appear at the top of the list regardless of date
                added.
              </div>
              {isFeatured && (
                <div className="col-6">
                  <div className="mb-3 pt-2">
                    <label htmlFor="colourName" className="form-label">
                      Colour
                    </label>
                    <CirclePicker
                      color={featureColour}
                          onChangeComplete={handleSetFeatureColour}>
                      </CirclePicker>
                  </div>
                  <div id="featureTickHelp" className="form-text">
                    Select a note colour for extra prominence
                  </div>
                </div>
              )}
            </form>
          ) : (
            <Fragment>
              {notes.map((note) => (
                <Fragment key={note.noteId}>
                  {note.edit ? (
                    <form
                      onSubmit={editNote}
                      className="p-3 bg-info bg-opacity-10 border border-info rounded"
                    >
                      <label htmlFor="assetDescription" className="form-label">
                        Edit note
                      </label>

                      <div className="float-end">
                        <button
                          type="button"
                          onClick={() => toggleEditNote(note.noteId)}
                          className="btn nopadding"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="22"
                            height="22"
                            fill="#CB444A"
                            className="bi bi-x-square"
                            viewBox="0 0 16 16"
                          >
                            <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                          </svg>
                        </button>
                        <span className="pe-3"></span>
                        {isSaving ? (
                          <div
                            className="spinner-border spinner-border-sm"
                            role="status"
                          >
                            <span className="visually-hidden">Updating...</span>
                          </div>
                        ) : (
                          <button type="submit" className="btn nopadding">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="22"
                              height="22"
                              fill="#00e39c"
                              className="bi bi-floppy2"
                              viewBox="0 0 16 16"
                            >
                              <path d="M1.5 0h11.586a1.5 1.5 0 0 1 1.06.44l1.415 1.414A1.5 1.5 0 0 1 16 2.914V14.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 14.5v-13A1.5 1.5 0 0 1 1.5 0ZM1 1.5v13a.5.5 0 0 0 .5.5H2v-4.5A1.5 1.5 0 0 1 3.5 9h9a1.5 1.5 0 0 1 1.5 1.5V15h.5a.5.5 0 0 0 .5-.5V2.914a.5.5 0 0 0-.146-.353l-1.415-1.415A.5.5 0 0 0 13.086 1H13v3.5A1.5 1.5 0 0 1 11.5 6h-7A1.5 1.5 0 0 1 3 4.5V1H1.5a.5.5 0 0 0-.5.5Zm9.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-1Z" />
                            </svg>
                          </button>
                        )}
                      </div>
                      {errorMessage !== null && (
                        <div className="pb-1">
                          <span className="badge rounded-pill text-bg-warning successmessage">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-database-check"
                              viewBox="0 0 16 16"
                            >
                              <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm1.679-4.493-1.335 2.226a.75.75 0 0 1-1.174.144l-.774-.773a.5.5 0 0 1 .708-.708l.547.548 1.17-1.951a.5.5 0 1 1 .858.514Z" />
                              <path d="M12.096 6.223A4.92 4.92 0 0 0 13 5.698V7c0 .289-.213.654-.753 1.007a4.493 4.493 0 0 1 1.753.25V4c0-1.007-.875-1.755-1.904-2.223C11.022 1.289 9.573 1 8 1s-3.022.289-4.096.777C2.875 2.245 2 2.993 2 4v9c0 1.007.875 1.755 1.904 2.223C4.978 15.71 6.427 16 8 16c.536 0 1.058-.034 1.555-.097a4.525 4.525 0 0 1-.813-.927C8.5 14.992 8.252 15 8 15c-1.464 0-2.766-.27-3.682-.687C3.356 13.875 3 13.373 3 13v-1.302c.271.202.58.378.904.525C4.978 12.71 6.427 13 8 13h.027a4.552 4.552 0 0 1 0-1H8c-1.464 0-2.766-.27-3.682-.687C3.356 10.875 3 10.373 3 10V8.698c.271.202.58.378.904.525C4.978 9.71 6.427 10 8 10c.262 0 .52-.008.774-.024a4.525 4.525 0 0 1 1.102-1.132C9.298 8.944 8.666 9 8 9c-1.464 0-2.766-.27-3.682-.687C3.356 7.875 3 7.373 3 7V5.698c.271.202.58.378.904.525C4.978 6.711 6.427 7 8 7s3.022-.289 4.096-.777ZM3 4c0-.374.356-.875 1.318-1.313C5.234 2.271 6.536 2 8 2s2.766.27 3.682.687C12.644 3.125 13 3.627 13 4c0 .374-.356.875-1.318 1.313C10.766 5.729 9.464 6 8 6s-2.766-.27-3.682-.687C3.356 4.875 3 4.373 3 4Z" />
                            </svg>
                            &nbsp;{errorMessage}
                          </span>
                        </div>
                      )}
                      <textarea
                        className="form-control"
                        value={editNoteText}
                        onChange={(event) => {
                          setEditNoteText(event.target.value)
                        }}
                        id="nwNoteText"
                        required={true}
                        rows="3"
                      ></textarea>
                      <div id="assetTypeNameHelp" className="form-text">
                        {editIsPrivate && note.deletable === true ? (
                          <span>
                            This is a private note which can only be seen by
                            you. Users in your organisaiton are unable to see
                            this note.
                          </span>
                        ) : (
                          <Fragment>
                            {editIsPrivate && note.deletable === false ? (
                              <span>
                                WARNING - if you set this note to private it
                                will no longer be visible to you. Only{" "}
                                {note.noteAddedByUser.userName} will have
                                access.
                              </span>
                            ) : (
                              <span>
                                This note can be seen by users in your
                                organisaiton.
                              </span>
                            )}
                          </Fragment>
                        )}
                      </div>

                      <div className="form-check form-switch pt-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={editIsPrivate}
                          value={editIsPrivate}
                          onChange={changeEditIsPrivateTick}
                          role="switch"
                          id="isPrivateSwitch"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="isPrivateSwitch"
                        >
                          Private
                        </label>
                      </div>

                      <div className="form-check form-switch pt-3">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          checked={editIsFeatured}
                          value={editIsFeatured}
                          onChange={changeEditFeaturedTick}
                          id="flexCheckDefault"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckDefault"
                        >
                          Featured
                        </label>
                      </div>

                      <div id="featureTickHelp" className="form-text">
                        Featured notes appear at the top of the list regardless
                        of date added.
                      </div>
                      {editIsFeatured && (
                        <div className="col-6">
                          <div className="mb-3 pt-2">
                            <label htmlFor="colourName" className="form-label">
                              Colour
                            </label>
                            <CirclePicker
                      color={editFeatureColour}
                          onChangeComplete={handlesetEditFeatureColour}>
                      </CirclePicker>
                            
                          
                          </div>
                          <div id="featureTickHelp" className="form-text">
                            Select a note colour for extra prominence
                          </div>
                        </div>
                      )}
                    </form>
                  ) : (
                    <div className="row">
                      <div className="col-10">
                        <figure key={note.noteId}>
                          {note.isfeatured ? (
                            <blockquote
                              style={{
                                borderLeft: "5px",
                                borderLeftStyle: "solid",
                                borderColor: note.featurecolour,
                              }}
                            >
                              <p className="ps-2">{note.notetext}</p>
                            </blockquote>
                          ) : (
                            <blockquote>
                              <p>{note.notetext}</p>
                            </blockquote>
                          )}
                          <figcaption className="blockquote-footer">
                            {note.private && (
                              <Fragment>
                                <Tooltip
                                  anchorSelect={
                                    ".my-anchor-element-secure" + note.noteId
                                  }
                                  place="top"
                                >
                                  <div>Private Note</div>
                                </Tooltip>
                                <span
                                  className={
                                    "my-anchor-element-secure" + note.noteId
                                  }
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-lock-fill"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z" />
                                  </svg>
                                </span>
                              </Fragment>
                            )}
                            {note.noteAddedByUser.userName} -{" "}
                            {note.noteCreatedOn}
                          </figcaption>
                        </figure>
                      </div>
                      <div className="col-2">
                        {note.deletable && globalEdit && (
                          <Fragment>
                            <button
                              type="button"
                              onClick={() => handleShow(note.noteId)}
                              className="btn btn-link float-end nopadding"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="22"
                                height="22"
                                fill="#CB444A"
                                className="bi bi-trash"
                                viewBox="0 0 16 16"
                              >
                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                                <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                              </svg>
                            </button>

                            <Modal show={show} onHide={handleClose}>
                              <Modal.Header closeButton>
                                <Modal.Title>Confim action</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                Delete this Note?
                                {deleteErrorMessage !== null && (
                                  <div className="pt-3">
                                    <span className="badge rounded-pill text-bg-dark successmessage">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="bi bi-database-check"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm1.679-4.493-1.335 2.226a.75.75 0 0 1-1.174.144l-.774-.773a.5.5 0 0 1 .708-.708l.547.548 1.17-1.951a.5.5 0 1 1 .858.514Z" />
                                        <path d="M12.096 6.223A4.92 4.92 0 0 0 13 5.698V7c0 .289-.213.654-.753 1.007a4.493 4.493 0 0 1 1.753.25V4c0-1.007-.875-1.755-1.904-2.223C11.022 1.289 9.573 1 8 1s-3.022.289-4.096.777C2.875 2.245 2 2.993 2 4v9c0 1.007.875 1.755 1.904 2.223C4.978 15.71 6.427 16 8 16c.536 0 1.058-.034 1.555-.097a4.525 4.525 0 0 1-.813-.927C8.5 14.992 8.252 15 8 15c-1.464 0-2.766-.27-3.682-.687C3.356 13.875 3 13.373 3 13v-1.302c.271.202.58.378.904.525C4.978 12.71 6.427 13 8 13h.027a4.552 4.552 0 0 1 0-1H8c-1.464 0-2.766-.27-3.682-.687C3.356 10.875 3 10.373 3 10V8.698c.271.202.58.378.904.525C4.978 9.71 6.427 10 8 10c.262 0 .52-.008.774-.024a4.525 4.525 0 0 1 1.102-1.132C9.298 8.944 8.666 9 8 9c-1.464 0-2.766-.27-3.682-.687C3.356 7.875 3 7.373 3 7V5.698c.271.202.58.378.904.525C4.978 6.711 6.427 7 8 7s3.022-.289 4.096-.777ZM3 4c0-.374.356-.875 1.318-1.313C5.234 2.271 6.536 2 8 2s2.766.27 3.682.687C12.644 3.125 13 3.627 13 4c0 .374-.356.875-1.318 1.313C10.766 5.729 9.464 6 8 6s-2.766-.27-3.682-.687C3.356 4.875 3 4.373 3 4Z" />
                                      </svg>
                                      &nbsp;{deleteErrorMessage}
                                    </span>
                                  </div>
                                )}
                              </Modal.Body>
                              <Modal.Footer>
                                <button
                                  type="button"
                                  className="btn btn-link"
                                  onClick={() => handleClose()}
                                >
                                  Close
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-outline-danger"
                                  onClick={() => removeNote()}
                                >
                                  Delete Note
                                </button>
                              </Modal.Footer>
                            </Modal>
                          </Fragment>
                        )}
                        {globalEdit && (
                          <Fragment>
                            <button
                              className="btn addbtn nopadding"
                              onClick={() => toggleEditNote(note.noteId)}
                              type="button"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="22"
                                height="22"
                                fill="currentColor"
                                className="bi bi-pencil-square"
                                viewBox="0 0 16 16"
                              >
                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                <path
                                  fillRule="evenodd"
                                  d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                />
                              </svg>
                            </button>
                          </Fragment>
                        )}
                      </div>
                    </div>
                  )}
                </Fragment>
              ))}
            </Fragment>
          )}
        </div>
      </div>
    </React.Fragment>
  )
}
export default Notes
