import React from "react"
import "./AuthenticatedHeader.css"
import "bootstrap/dist/css/bootstrap.css"
import "bootstrap/dist/js/bootstrap.bundle.js"

function AuthenticatedHeader(props) {
  
  return (
    <React.Fragment>
      {" "}
      <nav className="navbar navbar-heading">
        <nav
          className="container-xxl bd-gutter flex-wrap flex-lg-nowrap"
          aria-label="Main navigation"
        >
          <img
            src="https://cdn.comply-x.com/logos/Comply-X-Logo-White.svg"
            width="129"
            alt="Comply-X Logo"
          />
          <div>
          </div>
        </nav>
      </nav>
    </React.Fragment>
  )
}
export default AuthenticatedHeader
