import  {useEffect}from "react"
import { useNavigate } from "react-router-dom"
import { useParams } from "react-router-dom"

function Auth(props) {
 
  const { token } = useParams() // Auth Token

  const navigate = useNavigate()

  useEffect(() => {
    sessionStorage.setItem('loginGuid', token);
    navigate('/contractors'); // Need to do this to go live
  }, [token, navigate])

}
export default Auth
